import React,{useEffect} from "react";
// import web from "../../Images/about.png";
// import Common from "../Common";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Images/about1.png";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Team from './App'
const About = (props) => {
  const updatenumber = () =>{
    props.setProgress(100)
  }
  const updateData = async ()=> {
    props.setProgress(30);
  }


useEffect(() => {
    updateData(); 
    // eslint-disable-next-line
}, [])
  return (
    <>
     {(props.setProgress(50))}
      {/* <Common name = "Welcome to About Us Page" imgsrc={web} visit="/contact" btname= "Contact Now"  /> */}
      <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={6}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "" }}>
              Know Who <strong className="purple">We're</strong><hr/>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <LazyLoadImage alt={laptopImg} effect="blur" src={laptopImg} className="img-fluid" />
           
          </Col>
        </Row>
      </Container>
    </Container>
      {(props.setProgress(70))}
      {(updatenumber())}
      <Team />
    </>
  );
};

export default About;
