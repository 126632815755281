import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Empowering Small Businesses through Digital Transformation",
          "24x7 IT Solutions for Your Growth",
          "Driving Innovation to Scale Up Your Business",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 5,
        delay: 5, 
      }}
    />
  );
}

export default Type;
