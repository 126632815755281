import React from 'react'

import Project from "./ProjectNew";
const ProjectView = () => {
  return (
    <div className='container mt-5'>
      
    <div className='container mt-5'> <Project/></div>
       
    </div>
    
  )
}

export default ProjectView