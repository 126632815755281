import React from 'react';

import img1 from '../../Assets/Client imgs/30.jpg';
import img2 from '../../Assets/Client imgs/23.jpg';
import img3 from '../../Assets/Client imgs/7.jpg';
import img4 from '../../Assets/Client imgs/8.jpg';
// import img5 from '../../Assets/Client imgs/13.jpg';
import img6 from '../../Assets/Client imgs/25.jpg';
// import img7 from '../../Assets/Client imgs/4.jpg';
import img8 from '../../Assets/Client imgs/6.jpg';
import img9 from '../../Assets/Client imgs/11.jpg';
import img10 from '../../Assets/Client imgs/9.jpg';
// import img11 from '../../Assets/Client imgs/21.jpg';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImgNewSlider = () => {
  return (
    <div>
      <section id="gallery" className="gallery">
        <div className="container">
          <div className="section-title">
            <h2>Gallery</h2>
            <p>We are providing final year projects to BE, ME, BCA, MCA students. These all are recent students.</p>
          </div>
        </div>

        <div className="container">
          <div className="row g-0">
            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img1} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 1" effect="blur" src={img1} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img2} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 2" effect="blur" src={img2} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img9} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 9" effect="blur" src={img9} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img4} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 4" effect="blur" src={img4} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img6} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 6" effect="blur" src={img6} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img8} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 8" effect="blur" src={img8} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img3} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 3" effect="blur" src={img3} height="200px" width="100%" />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="gallery-item">
                <a href={img10} className="gallery-lightbox">
                  <LazyLoadImage alt="Client Image 10" effect="blur" src={img10} height="200px" width="100%" />
                </a>
              </div>
            </div>

           
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImgNewSlider;
