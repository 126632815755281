import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
export default function Studenttestimonials() {
  
  return (
    <MDBContainer>
      <MDBRow className="d-flex justify-content">
        <MDBCol md="10" xl="8" className="">
          <h2 className="fw-bold mx-2 mb-4">Recent Reviews</h2>
        </MDBCol>
      </MDBRow>
      <Slide>
   
        <MDBContainer><MDBRow className="text-center m-2" >
      <MDBCol md="4" className="mb-4 mb-md-0">
          <MDBCard style={{boxShadow: "0 4px 5px 3px rgba(157, 157, 157, 0.4)", background:"#FAF9F6"}}>
            <MDBCardBody className="py-4 mt-2">
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                It was a great experience having Fixco.in team. Thank you so much for outstanding services.
              </p>
              <h5 className="font-weight-bold "> - Khushi Jaiswal BCA (VBMV)</h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-4 mb-md-0">
          <MDBCard style={{boxShadow: "0 4px 5px 3px rgba(157, 157, 157, 0.4)", background:"#FAF9F6"}}>
            <MDBCardBody className="py-4 mt-2">
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                I was thoroughly impressed with the ML-Powered Face Mask Detection System presented by your team.
              </p>
              <h5 className="font-weight-bold "> - Shivani Chaudhari BE (DRGITR)</h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-4 mb-md-0">
          <MDBCard style={{boxShadow: "0 4px 5px 3px rgba(157, 157, 157, 0.4)", background:"#FAF9F6"}}>
            <MDBCardBody className="py-4 mt-2">
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                The colorful visuals, interactive UI, and user-friendly interfaces. This project embodies the true spirit of using technology for social good.
              </p>
              <h5 className="font-weight-bold "> - Ishwari Ingole BCA (BIYANI) </h5>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow></MDBContainer></Slide>
      
    </MDBContainer>
  );
}