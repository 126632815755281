import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view" style={{boxShadow:"none"}}>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <strong>About Us: </strong><br/>
          <p style={{ textAlign: "justify", fontSize:"17px"}}>
            Welcome to <span><strong>FIXCO.IN</strong></span>, your trusted partner in guiding and empowering college students through their final year projects.
            We understand the significance of these projects in shaping a student's academic journey and future career. Our mission is to provide comprehensive support, resources, and expertise to ensure that students gets exceptional projects that stand out. 
          </p><hr/>
          <strong>Our Story: </strong><br/>
          <p style={{ textAlign: "justify", fontSize:"17px"}}>
            Founded in 2018, <span><strong>FIXCO.IN</strong></span> was born out of a shared passion for education and a desire to bridge the gap between classroom learning and real-world application. Our founders, a team of experienced educators and experienced developers, recognized the challenges students face in conceptualizing, planning, and executing their final year projects. They envisioned a platform that would provide students with the tools and guidance needed to turn their project ideas into successful realities.
           
          </p>
          <hr/>
          <strong>What We Offer: </strong><br/>
          <p style={{ textAlign: "justify", fontSize:"17px"}}>
             
             At <span><strong>FIXCO.IN</strong></span>, we offer a holistic approach to final year projects. Our services are designed to support students at every stage of their project journey:
             </p>

            <li className="about-activity" style={{fontSize:"17px"}}>
              <ImPointRight /> Project Ideation and Selection
            </li>
            <li className="about-activity" style={{fontSize:"17px"}}>
              <ImPointRight /> Project Planning and Design
            </li>
            <li className="about-activity" style={{fontSize:"17px"}}>
              <ImPointRight /> Mentorship and Consultation
            </li>
            <li className="about-activity" style={{fontSize:"17px"}}>
              <ImPointRight /> 24x7 Available Support
            </li>
          <hr/>
          <strong>Our Values: </strong><br/>

            <li className="about-activity" style={{fontSize:"17px"}}>
             <strong>Student-Centric: </strong> We prioritize the needs and aspirations of students, tailoring our services to ensure their success.
            </li>
            <li className="about-activity" style={{fontSize:"17px"}}>
             <strong> Excellence: </strong> We are committed to maintaining the highest standards of quality and helping students create projects that exemplify excellence.
            </li>
            <li className="about-activity" style={{fontSize:"17px"}}>
             <strong> Innovation: </strong>We encourage innovative thinking and guide students to develop projects that contribute to cutting-edge advancements.
            </li>
       
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
